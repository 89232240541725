import { gql, useLazyQuery } from "@apollo/client"
import { Box, Grid, Typography, Divider } from "@material-ui/core"
import { useLocation } from "@reach/router"
import React, { useEffect } from "react"
import { last, dropRight, slice, join } from "lodash"
import {
  ProductItemCard,
  productItemCardFragment,
} from "../components/ProductItemCard"
import { ProductItemCardFragment } from "../components/ProductItemCard/__generated__/ProductItemCardFragment"

import { Layout } from "../components/layout"
import SEO from "../components/seo"

const query = gql`
  query FindPotentialProducts($productTitle: String!) {
    search_products(args: { title: $productTitle, lim: 9 }) {
      id
      ...ProductItemCardFragment
    }
  }
  ${productItemCardFragment}
`

const NotFoundPage = () => {
  const loc = useLocation()
  const [doQuery, { data }] = useLazyQuery(query)
  const alternatives: ProductItemCardFragment[] = data?.search_products ?? []

  useEffect(() => {
    const urlParts = loc.pathname.split("/")
    const queryPiece = decodeURIComponent(
      last(urlParts.filter(part => !!part)) ?? ""
    )
    const productTitle = join(
      slice(dropRight(queryPiece?.split("-")), 0, 8),
      " "
    )

    if (productTitle) {
      doQuery({
        variables: {
          productTitle,
        },
      })
    }
  }, [])

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Typography variant="h1">404: Not Found</Typography>
      <Box mt={2} mb={2}>
        <Typography variant="body1">
          You just hit a route that doesn&#39;t exist... the sadness. Try
          searching The Liquor Project to see if perhaps the page you were
          looking for has moved.
        </Typography>
      </Box>

      {alternatives.length ? (
        <Box pt={2} pb={2}>
          <Box>
            <Typography variant="h4" component="h3">
              Were you looking for any of these?
            </Typography>
          </Box>
          <Box marginY={2}>
            <Divider />
          </Box>
          <Grid container spacing={1} direction="row">
            {alternatives.map(product => (
              <Grid md={4} xs={12} key={product.id} item>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <ProductItemCard product={product} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}
    </Layout>
  )
}

export default NotFoundPage
